import { ReactComponent as ErrorCrossIcon } from '../../assets/error-cross.svg'
import styles from './InvalidHashDisplay.module.css'

export default function InvalidHashDisplay() {
    return <div className={styles.errorDisplayContainer}>
                <ErrorCrossIcon />
                <h5>
                    This NFT has been claimed.
                </h5>
            </div>
}
