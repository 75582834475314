import { ReactComponent as SuccessTickIcon } from '../../assets/success-tick.svg'
import styles from './SuccessDisplay.module.css'

export default function SuccessDisplay(props) {
    return <div className={styles.successDisplayContainer}>
                <SuccessTickIcon />
                <h5>
                    Congrats! You’ve minted your 
                    <br />
                    NFT successfully.
                </h5>
                <a target="_blank" href={props.buttonLink} rel="noreferrer">View your NFT on OpenSea</a>
            </div>
}
