import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";
import Claim from "./components/Claim";
import Page404 from "./components/Page404";
import Home from "./components/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  breakpoints: {
    values: {
      // xs: 0,
      sm: 375,
      md: 768,
      lg: 1024,
      // xl: 1536,
    },
  },
});

function App() {
  const betaMode = true;

  return (
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        {betaMode ? <Header betaMode={betaMode} /> : <Header />}
        <Routes>
          <Route index element={<Home />} />
          <Route path="/:event_name/:hash" element={<Claim />} />
          {/* TODO: A fallback default page */}
          <Route path="/404" element={<Page404 />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
