import React from "react";
import { useMediaQuery, Grid } from "@mui/material";
import styles from "./Home.module.css";
import { ReactComponent as HomepageImage } from "../assets/Homepage.svg";

export default function Home() {
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const messageBody =
    "I'm interested to create my event Proof-Of-Attendance NFT. Please see details below:" +
    "\n\n Event Name:" +
    "\nEvent Date: " +
    "\nQuantity of NFTs to be distributed: " +
    "\nOther Information: " +
    "\n\nThank you.";

  function createNFTEmailHandler() {
    const destinationEmail = encodeURI("support-acentrik@mercedes-benz.com");
    const subject = encodeURI("Create my event NFT");
    const res = encodeURI(messageBody);

    const messageBuilder = `mailto:${destinationEmail}?subject=${subject}&body=${res}`;

    window.location.href = messageBuilder;
  }

  return (
    <>
      <div id="stars"></div>
      <div id="stars2"></div>
      <Grid
        className={styles.container}
        container
        align="center"
        direction={largeScreen ? "row" : "column-reverse"}
        columnGap={largeScreen ? 19 : 0}
      >
        <Grid item lg={4} md={9} sm={12} xs={12} className={styles.leftPanel}>
          <div className={styles.headerTagline}>
            Make your events memorable.
          </div>
          <div className={styles.bodyTagline}>
            With Memento, create unique Proof-Of-Attendance NFT experiences for
            your attendees and commemorate in style – made possible with your
            event’s customized token.
          </div>

          <div className={styles.createEventButton}>
            <a onClick={createNFTEmailHandler}>Get Started</a>
          </div>
        </Grid>
        <Grid item lg={5} md={9} sm={12} xs={12} className={styles.rightPanel}>
          <HomepageImage />
        </Grid>
      </Grid>
    </>
  );
}
