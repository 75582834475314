import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import styles from "./Page404.module.css";
import { ReactComponent as Image404 } from "../assets/404.svg";

export default function Page404() {
  return (
    <>
      <div id="stars"></div>
      <div id="stars2"></div>
      <Grid className={styles.container} container align="center">
        <Grid item>
          <Image404 />
          <div className={styles.subtitle}>
            <p>Uh oh! Your link is invalid</p>
            <p>
              Please use the link in your email
              <br />
              sent by the event organizer.
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
