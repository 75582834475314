import configEIP2771 from "./gaslessContract.json";
import nonprodConfigEIP2771 from "./nonprodGaslessContracts.json";

export { configEIP2771, nonprodConfigEIP2771 };

export type ExternalProvider = {
  isMetaMask?: boolean,
  isStatus?: boolean,
  host?: string,
  path?: string,
  sendAsync?: (
    request: { method: string, params?: Array<any> },
    callback: (error: any, response: any) => void
  ) => void,
  send?: (
    request: { method: string, params?: Array<any> },
    callback: (error: any, response: any) => void
  ) => void,
  request?: (request: { method: string, params?: Array<any> }) => Promise<any>,
};

export const networkPolygon = {
  chainId: "0x89",
  rpcUrls: ["https://matic-mainnet.chainstacklabs.com"],
  chainName: "Matic Mainnet",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrls: ["https://polygonscan.com"],
};

export const networkMumbai = {
  chainId: "0x13881",
  rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
  chainName: "Mumbai",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  blockExplorerUrls: ["https://mumbai.polygonscan.com"],
};
