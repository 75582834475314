import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { ReactComponent as MercedesIcon } from "../assets/Mercedes_Benz.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function Header(props) {
  const { betaMode } = props;
  const [isShowBanner, setIsShowBanner] = useState(betaMode);

  const messageBody =
    "I'm interested to create my event Proof-Of-Attendance NFT. Please see details below:" +
    "\n\n Event Name:" +
    "\nEvent Date: " +
    "\nQuantity of NFTs to be distributed: " +
    "\nOther Information: " +
    "\n\nThank you.";

  function createNFTEmailHandler() {
    const destinationEmail = encodeURI("support-acentrik@mercedes-benz.com");
    const subject = encodeURI("Create my event NFT");
    const res = encodeURI(messageBody);

    const messageBuilder = `mailto:${destinationEmail}?subject=${subject}&body=${res}`;

    window.location.href = messageBuilder;
  }

  function checkShowBanner() {
    if (!isShowBanner) {
      return false;
    }

    const currentPage = window.location.pathname;
    if (currentPage !== "/") {
      return false;
    } else {
      // Only show banner if the URl is "/"
      return true;
    }
  }

  return (
    <>
      {checkShowBanner() && (
        <div className={styles.betaPhaseBanner}>
          <div className={styles.bannerContent}>
            <div className={styles.bannerIcon}>
              <ErrorOutlineIcon />
            </div>
            <div className={styles.bannerContentText}>
              This product and features are currently in Beta phase. Please
              <span
                className={styles.contactUsLink}
                onClick={createNFTEmailHandler}
              >
                {" "}
                contact us{" "}
                <OpenInNewIcon
                  className={styles.openNewIcon}
                  onClick={createNFTEmailHandler}
                />
              </span>
              {` `}to join the waitlist for your upcoming events.
            </div>
          </div>
          <div className={styles.bannerClose}>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => setIsShowBanner(false)}
            />
          </div>
        </div>
      )}
      <div className={styles.navigationBar}>
        <Link to="/">
          <MercedesIcon />
        </Link>
      </div>
    </>
  );
}
