import React from "react";
import styles from "./Footer.module.css";
import { ReactComponent as AcentrikLogo } from "../assets/Acentrik.svg";
import { ReactComponent as PolygonLogo } from "../assets/Polygon.svg";
import { Grid } from "@mui/material";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Grid className={styles.gridContainer} container>
        <Grid item className={styles.gridItem}>
          Powered by
          <a target="_blank" href="https://acentrik.io" rel="noreferrer">
            <AcentrikLogo />
          </a>
        </Grid>
        <Grid item className={styles.gridItem}>
          Deployed on
          <a target="_blank" href="https://polygon.technology" rel="noreferrer">
            <PolygonLogo />
          </a>
        </Grid>
      </Grid>
    </footer>
  );
}
