import {ReactComponent as OutOfSupplyIcon } from '../../assets/out-of-supply.svg'
import styles from './OutOfSupplyDisplay.module.css'

export default function OutOfSupplyDisplay() {
  return (
    <div className={styles.outOfSupplyDisplayContainer}>
        <OutOfSupplyIcon/>
        <h5>
        Sorry, all Proof-Of-Attendance NFTs for this event <br/>
        have been claimed.
        </h5>
    </div>
  )
}