import { ReactComponent as ErrorCrossIcon } from '../../assets/error-cross.svg'
import styles from './ErrorDisplay.module.css'

export default function ErrorDisplay() {
    return <div className={styles.errorDisplayContainer}>
                <ErrorCrossIcon />
                <h5>
                    Something went wrong.<br />
                    Please refresh page &amp; try again.
                </h5>
            </div>
}
