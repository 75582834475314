import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import styles from "./Claim.module.css";
import { Biconomy } from "@biconomy/mexa";
import { Button, Grid, useMediaQuery, TextField } from "@mui/material";
import { ReactComponent as NewTabIcon } from "../assets/expand-new-tab.svg";
import { ReactComponent as CircularSpinner } from "../assets/circular-spinner.svg";
import { ReactComponent as TriangleWarning } from "../assets/triangle-warning.svg";
import axios from "axios";
import ErrorDisplay from "./molecules/ErrorDisplay";
import InvalidHashDisplay from "./molecules/InvalidHashDisplay";
import EventEndedDisplay from "./molecules/EventEndedDisplay";
import OutOfSupplyDisplay from "./molecules/OutOfSupplyDisplay";
import SuccessDisplay from "./molecules/SuccessDisplay";
import {
  configEIP2771 as config,
  nonprodConfigEIP2771 as nonprodConfig,
  networkPolygon,
  networkMumbai,
} from "../utils/";

const MESSAGE_NO_CONNECT_WEB3 =
  "Please connect your Web3.0 Wallet to claim your NFT.";
const MESSAGE_SUCCESS_CONNECT_WEB3 = "Wallet successfully connected!";
const MESSAGE_CONNECTED_WEB3 = "Wallet connected";
const NETWORK_CONFIG = process.env.REACT_APP_NETWORK || "MUMBAI";

export default function Claim() {
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const { event_name, hash } = useParams();
  const provider = window.ethereum
    ? new ethers.providers.Web3Provider(window.ethereum)
    : undefined;

  if (!provider) {
    console.log("Provider not found");
  }

  let biconomy;
  let contractInstance;

  const [isEventValid, setEventValid] = useState(false);
  const [connected, setConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isValidNetwork, setValidNetwork] = useState(false);
  const [isSwitchingNetwork, setSwitchingNetwork] = useState(false);
  const [isAccountClaimed, setIsAccountClaimed] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [isClaiming, setIsClaiming] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openSeaLink, setOpenseaLink] = useState("");
  const [error, setError] = useState();
  const [invalidHash, setInvalidHash] = useState(false);
  const [eventEnded, setEventEnded] = useState(false);
  const [isOutOfSupply, setIsOutOfSupply] = useState(false);
  const [eventName, setEventName] = useState("");
  const [nftImage, setNftImage] = useState("");
  const [eventId, setEventId] = useState();
  const [tokenId, setTokenId] = useState();

  useEffect(() => {
    async function getMetadata() {
      const baseUrl = process.env.REACT_APP_URL || config.url.nonprod;
      await axios({
        method: "get",
        url: baseUrl + "events/" + event_name + ".json",
        responseType: "json",
      })
        .then(function (response) {
          if (response.data.eventId === undefined) {
            navigate("/404");
          }
          setEventId(response.data.eventId);
          setTokenId(response.data.tokenId);
          setEventValid(true);
          setEventName(response.data.name);
          setNftImage(response.data.image);
        })
        .catch(function (error) {
          navigate("/404");
        });
    }

    getMetadata();

    if (provider) {
      biconomy = new Biconomy(window.ethereum, {
        apiKey: process.env.REACT_APP_BICONOMY_API_KEY || config.apiKey.test,
        debug: true,
        contractAddresses:
          process.env.REACT_APP_NETWORK == "MUMBAI"
            ? nonprodConfig.contract.map((x) => x.address)
            : config.contract.map((x) => x.address),
      });

      if (eventId) {
        contractInstance = new ethers.Contract(
          biconomy.contractAddresses[eventId],
          process.env.REACT_APP_NETWORK == "MUMBAI"
            ? nonprodConfig.contract[eventId].abi
            : config.contract[eventId].abi,
          biconomy.ethersProvider
        );

        setIsInitializing(true);
        if (hashCheck(contractInstance, hash)) {
          eventAvailabilityCheck(contractInstance);
        }
        setIsInitializing(false);

        window.ethereum.on("accountsChanged", async function (account) {
          if (account.length > 0) {
            setWalletAddress(account[0]);
            isValidNetwork && (await isConnectedAccountClaimed(account[0]));
          } else {
            setConnected(false);
            setIsConnecting(false);
            setIsClaiming(false);
            setSwitchingNetwork(false);
            setIsAccountClaimed(false);
          }
        });

        // KIV networkChange for firefox no work properly:
        window.ethereum.on("chainChanged", function (chainId) {
          let networkId = parseInt(chainId, 16);
          updatesOnNetworkChange(networkId);
        });
      }
    }
  });

  useEffect(() => {
    if (connected && contractInstance) return;

    async function isMetamaskConnected() {
      const accounts = await provider.listAccounts();
      if (accounts.length > 0 && contractInstance) {
        setWalletAddress(accounts[0]);
        setConnected(true);
        updatesOnNetworkChange(parseInt(window.ethereum.chainId, 16));
      }
    }

    if (provider) {
      isMetamaskConnected();
    }
  }, [connected]);

  function updatesOnNetworkChange(networkId, isInitialConnect) {
    try {
      if (networkId == 80001 && NETWORK_CONFIG === "MUMBAI") {
        setValidNetwork(true);
        setOpenseaLink(
          `https://testnets.opensea.io/assets/mumbai/${contractInstance.address}/${tokenId}`
        );
        !isInitialConnect && requestAccount();
      } else if (networkId == 137 && NETWORK_CONFIG === "MATIC") {
        setValidNetwork(true);
        setOpenseaLink(
          `https://opensea.io/assets/matic/${contractInstance.address}/${tokenId}`
        );
        !isInitialConnect && requestAccount();
      } else {
        console.log("Detected Incorrect Network");
        setIsAccountClaimed(false);
        setValidNetwork(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function hashCheck(contractInstance, hash) {
    if (eventId == 0) {
      return true;
    }

    let res = await contractInstance.whitelist(hash);
    if (res == "unclaimed" || res == "active") {
      setInvalidHash(false);
      return true;
    } else if (!res) {
      navigate("/404");
      return false;
    } else {
      setInvalidHash(true);
      return false;
    }
  }

  async function eventAvailabilityCheck(contractInstance) {
    if (eventId == 0) {
      return true;
    }
    const currentEpochTime = Math.floor(new Date().getTime() / 1000);
    const eventExpiry = await contractInstance.eventExpiry(tokenId);

    // If event has ended:
    if (currentEpochTime > Number(eventExpiry._hex)) {
      setEventEnded(true);
      return false;
    }

    const remSupply = await contractInstance.existingEventSupply(tokenId);
    const supplyLimit = await contractInstance.maxEventSupply(tokenId);
    // If event is out of supply:
    if (Number(remSupply._hex) >= Number(supplyLimit._hex)) {
      setIsOutOfSupply(true);
      return false;
    }
    return true;
  }

  async function switchToMainNetwork() {
    setSwitchingNetwork(true);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: NETWORK_CONFIG === "MUMBAI" ? "0x13881" : "0x89",
          },
        ],
      });
      setSwitchingNetwork(false);
    } catch (switchError) {
      if (switchError.code === 4902 || switchError.code === -32603) {
        // firefox bug if nothing here
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params:
              NETWORK_CONFIG === "MUMBAI" ? [networkMumbai] : [networkPolygon],
          });
          setSwitchingNetwork(false);
          setValidNetwork(true);
          return;
        } catch (addError) {
          setError(true);
        }
        setSwitchingNetwork(false);
      }
      setError(true);
    }
  }

  function accountTruncate(account) {
    if (!account || account === "") return;
    const middle = account.substring(4, 36);
    const truncated = account.replace(middle, "…");
    return truncated;
  }

  async function isConnectedAccountClaimed(accountAddress) {
    try {
      const result = await contractInstance.balanceOf(accountAddress, tokenId);
      if (parseInt(result._hex, 16) > 0) {
        setIsAccountClaimed(true);
      } else {
        setIsAccountClaimed(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function requestAccount() {
    if (provider) {
      setIsConnecting(true);
      try {
        let accounts = await provider.send("eth_requestAccounts", []);
        setWalletAddress(accounts[0]);

        await isConnectedAccountClaimed(accounts[0]);
        setConnected(true);
        setIsConnecting(false);
        if (!isValidNetwork && window.ethereum) {
          updatesOnNetworkChange(parseInt(window.ethereum.chainId, 16), true);
        }
      } catch (error) {
        console.log(error);
        if (error.code === 4001) setIsConnecting(false);
      } finally {
        setIsClaiming(false);
        setError(undefined);
      }
    }
  }

  async function claimNft() {
    try {
      setIsClaiming(true);
      await biconomy.init();
      const biconomyProvider = biconomy.provider;

      // TODO: Refactor to improve event detection for respective mint() call
      let { data } = await contractInstance.populateTransaction.mint(
        walletAddress,
        tokenId,
        eventId != 0 ? hash : undefined
      );
      let txParams = {
        data: data,
        to:
          process.env.REACT_APP_NETWORK == "MUMBAI"
            ? nonprodConfig.contract[eventId].address
            : config.contract[eventId].address,
        from: walletAddress,
        signatureType: "PERSONAL_SIGN",
      };

      const result = await biconomyProvider.sendAsync({
        method: "eth_sendTransaction",
        params: [txParams],
      });

      if (!result.transactionId) {
        setIsClaiming(false);
        setError(result);
        return;
      }

      biconomy.on("txHashGenerated", (data) => {
        console.log("[txHashGenerated]", data);
      });

      biconomy.on("txMined", (data) => {
        console.log("[txMined]", data);
        setIsClaiming(false);
        setIsSuccess(true);
      });

      biconomy.on("onError", (data) => {
        setIsClaiming(false);
        setError(data);
        console.log("[onError]", data);
      });

      biconomy.on("txHashChanged", (data) => {
        // KIV for any triggering scenario for TODO action:
        console.log("[txHashChanged]", data);
      });
    } catch (error) {
      setIsClaiming(false);
      setError(error);
      return;
    }
  }

  function checkWeb3Wallet() {
    // add logic for check web3 wallet here
    if (isAccountClaimed)
      return (
        <p className={styles.web3ConnectedMessage}>
          {" "}
          {MESSAGE_CONNECTED_WEB3}{" "}
        </p>
      );
    if (!connected || !provider)
      return (
        <p className={styles.web3NoConnectMessage}>
          {" "}
          {MESSAGE_NO_CONNECT_WEB3}{" "}
        </p>
      );
    if (connected)
      return (
        <p className={styles.web3ConnectedMessage}>
          {" "}
          {MESSAGE_SUCCESS_CONNECT_WEB3}{" "}
        </p>
      );
  }

  function displayHeaderText() {
    if (invalidHash || isOutOfSupply || eventEnded || error) {
      return (
        <h4>
          <span></span>
        </h4>
      );
    }

    if (!connected || (connected && !isValidNetwork)) {
      return (
        <h4>
          <span>You're one step away from claiming your NFT!</span>
        </h4>
      );
    } else if (connected && !isSuccess && !isAccountClaimed) {
      return (
        <h4>
          <span>Congratulations! Your NFT is ready to be claimed </span>✨
        </h4>
      );
    } else if ((connected && isSuccess) || isAccountClaimed) {
      return (
        <h4>
          <span>You've claimed your NFT for this event.</span>
        </h4>
      );
    }
  }

  // Add a bool to straight show error on HashCheck
  return (
    isEventValid && (
      <>
        <div id="stars"></div>
        <div id="stars2"></div>
        <Grid
          className={styles.container}
          container
          align="center"
          columnGap={largeScreen ? 22 : 0}
        >
          <Grid
            item
            lg={12}
            md={9}
            sm={12}
            xs={12}
            className={styles.congratsMessage}
          >
            {displayHeaderText()}
          </Grid>

          <Grid
            item
            lg={4}
            md={12}
            sm={12}
            xs={12}
            className={styles.leftPanel}
          >
            <img src={nftImage} alt="nft" />
          </Grid>

          <Grid
            item
            lg={5}
            md={8}
            sm={12}
            xs={12}
            className={styles.rightPanel}
          >
            {isSuccess || isAccountClaimed ? (
              <SuccessDisplay buttonLink={openSeaLink} />
            ) : invalidHash ? (
              <InvalidHashDisplay />
            ) : eventEnded ? (
              <EventEndedDisplay />
            ) : isOutOfSupply ? (
              <OutOfSupplyDisplay />
            ) : error ? (
              <ErrorDisplay />
            ) : (
              <>
                {isInitializing ? (
                  <div className={styles.isConnectingContainer}>
                    <CircularSpinner />
                    <h5>Please Wait: {`Initializing`} </h5>
                  </div>
                ) : (
                  <>
                    <h1>{eventName}</h1>
                    {isConnecting || isClaiming || isSwitchingNetwork ? (
                      <div className={styles.isConnectingContainer}>
                        <CircularSpinner />
                        <h5>
                          Please Wait:{" "}
                          {isConnecting
                            ? `Connecting Wallet`
                            : isSwitchingNetwork
                            ? `Switching Network`
                            : `Claiming NFT`}{" "}
                        </h5>
                      </div>
                    ) : (
                      <div className={styles.connectContainer}>
                        <div className={styles.connectContainerMessage}>
                          {checkWeb3Wallet()}
                        </div>
                        {connected && (
                          <TextField
                            FormHelperTextProps={{
                              sx: {
                                "&.Mui-error": {
                                  color: isAccountClaimed
                                    ? "#F76969"
                                    : "#FFA857",
                                },
                                path: {
                                  stroke:
                                    !isValidNetwork &&
                                    !isAccountClaimed &&
                                    "#FFA857",
                                },
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-error": {
                                  color: isAccountClaimed
                                    ? "#F76969"
                                    : "#FFA857",
                                },
                                "&.Mui-disabled": {
                                  color: "#4ECDC4 !important",
                                },
                              },
                            }}
                            InputProps={{
                              disableUnderline: true,
                              sx: {
                                "&.Mui-disabled": {
                                  backgroundColor:
                                    "linear-gradient(90deg, rgba(75, 170, 255, 0.1) 0%, rgba(77, 189, 223, 0.1) 57%, rgba(78, 205, 196, 0.1) 100%)",
                                  border: 2,
                                  borderRadius: 0,
                                  borderColor: "rgba(78, 205, 196, 0.6)",
                                },
                                "&.Mui-error": isAccountClaimed
                                  ? {
                                      border: 2,
                                      borderColor: "#E95656",
                                      backgroundColor: "rgba(233, 86, 86, 0.1)",
                                    }
                                  : {
                                      color: "#FFA857 !important",
                                      border: 2,
                                      borderColor: "#FFA857",
                                      backgroundColor: "rgba(233, 86, 86, 0.1)",
                                    },
                                "& input:-webkit-autofill": {
                                  boxShadow: "0 0 0 100px inset",
                                },
                                "& input:-moz-autofill": {
                                  boxShadow: "none",
                                },
                                "& input:autofill": {
                                  boxShadow: "none",
                                },
                                "& .Mui-disabled": {
                                  WebkitTextFillColor: "white !important",
                                },
                              },
                            }}
                            className={styles.walletIdField}
                            label="Wallet ID"
                            variant="filled"
                            value={accountTruncate(walletAddress)}
                            disabled
                            error={isAccountClaimed || !isValidNetwork}
                            helperText={
                              (isAccountClaimed || !isValidNetwork) && (
                                <span className={styles.claimedError}>
                                  <TriangleWarning />{" "}
                                  <span>
                                    {" "}
                                    {isAccountClaimed
                                      ? `This Wallet has already claimed the NFT.`
                                      : `Unsupported network. Please switch to Polygon Mainnet.`}{" "}
                                  </span>
                                </span>
                              )
                            }
                          />
                        )}
                        <div className={styles.connectButton}>
                          <Button
                            onClick={
                              !connected
                                ? requestAccount
                                : !isValidNetwork
                                ? switchToMainNetwork
                                : claimNft
                            }
                            disabled={isAccountClaimed}
                            sx={{
                              "&.Mui-disabled": {
                                background:
                                  "linear-gradient(90deg, rgba(75, 170, 255, 0.2) 0%, rgba(46, 179, 219, 0.2) 57%, rgba(78, 205, 196, 0.2) 100%)",
                                border: "2px solid rgb(77, 189, 223, 0.1)",
                                color: "#6A6E84",
                              },
                            }}
                          >
                            {!connected
                              ? "Connect Wallet"
                              : isAccountClaimed
                              ? "Claim NFT"
                              : !isValidNetwork
                              ? "Switch Network"
                              : "Claim NFT"}
                          </Button>
                        </div>
                      </div>
                    )}
                    {/* <div className={styles.noWalletTooltip}>
                      Don't have wallet?&nbsp;
                      <a
                        target="_blank"
                        href="https://support.acentrik.io/help/en-us/8-starter-kit/71-wallet-set-up-metamask-reference"
                        rel="noreferrer"
                      >
                        Let's create one here <NewTabIcon />
                      </a>
                    </div> */}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>
    )
  );
}
