import {ReactComponent as EventEndIcon } from '../../assets/event-end.svg'
import styles from './EventEndedDisplay.module.css'

export default function EventEndedDisplay() {
  return (
    <div className={styles.eventEndedDisplayContainer}>
        <EventEndIcon/>
        <h5>
        Sorry, the claim period for this <br/>
        Proof-Of-Attendance NFT has ended.
        </h5>
    </div>
  )
}